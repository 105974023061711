import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export const FreudCover = ({ lang }) =>
    <StaticImage
        src={"../../../assets/images/freud.jpg"}
        alt={lang === "en" ? "The Reception and Rendition of Freud in China: China’s Freudian Slip, edited by Tao Jiang and Philip J. Ivanhoe" : "佛洛伊德与中国（蒋韬、艾文贺合编）"}
        placeholder="blurred"
        imgStyle={{ objectFit: "contain" }}
        style={{ height: "100%", width: "100%" }}
    />
import React from "react";
import Book from "../subpage_src/components/Book";
import data from '../data/Data';
import Layout from "../subpage_src/components/Layout";
import {FreudCover} from "../subpage_src/components/partials/book_covers/FreudCover";
import tableOfContents from "../data/table_of_contents/freud.pdf";

const FreudAndChina = () => {
    return (
        <Layout lang={"en"}>
            <Book
                lang={"en"}
                Cover = { FreudCover }
                bookData = {data.freud}
                pdfFile={tableOfContents}
            />
        </Layout>
    )

}

export default FreudAndChina;